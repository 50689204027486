.container {
  display: grid;
  gap: 16px;
  @media (min-width: 1024px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
.topicButton {
  max-width: 100%;
  height: 100%;
  min-height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-wrap: wrap;
  white-space: normal;
  border-color: #14141733;
  color: #141417;
}
