.chartContainer {
    max-width: 100%;
    width: 100%;
    padding: 32px;
    border: 2px solid #e4e4e4;
    border-radius: 16px;
    overflow: auto;
}

.titleWrap {
    margin-bottom: 24px;

    @media (max-width: 576px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
    }
}

.title {
    margin-bottom: 0 !important;
}

.predictionLabel {
    font-size: 14px;
}

.predictionWrap{
    margin-left: auto;

    @media (max-width: 576px) {
        margin-left: inherit;
    }
}