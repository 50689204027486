.container {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.titleContainer {
  margin-top: 82px;
  margin-bottom: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
}
.answerTitle {
  margin: 12px 0;
  font-size: 28px;
  color: #d31e25;
}
.answer {
  padding: 24px 40px;
  border-radius: 24px;
  font-size: 20px;
  font-weight: 400;
  background-color: #c3c4c94d;

  @media (min-width: 760px) {
    font-size: 24px;
  }
}
.info{
  display: flex;
  flex-direction: column;
  gap: 40px;
  font-size: 20px;
  font-weight: 400;

  @media (min-width: 760px) {
    font-size: 24px;
  }
}