.description {
  text-align: center;
}

.footer {
  margin: 20px 0;
  display: flex;
  gap: 24px;
  justify-content: center;
  align-items: center;
}

.footerBtn {
  width: 220px;
}

.tablesWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.tableTitle {
  font-size: 16px;
  font-weight: 700;
  text-align: center;
}

.tableSubTitle {
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}

.closeIcon,
.editIcon {
  font-size: 32px;
  cursor: pointer;
}

.closeIcon {
  color: #0c0d0d;
}

.editIcon {
  color: #D31E25;
}

.iconsWrapper {
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  position: relative;
  right: 25px;
  background-color: white;
}

.modalTitle {
  padding: 0 70px;
}

@media only screen and (max-width: 425px) {
  .footer {
    flex-direction: column;
  }

  .modalTitle {
    padding: 32px 0 0;
  }
}
