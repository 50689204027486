.container {
  max-width: 480px;
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.uploadButton {
  color: rgba(255, 255, 255, 1);
  background-color: rgba(20, 20, 23, 1);
}

.deleteButton {
  max-width: 140px;
  color: rgba(20, 20, 23, 1);
  border-color: rgba(20, 20, 23, 1);
}

@media (max-width: 500px) {
  .container {
    flex-direction: column;
    gap: 20px;
  }
}
