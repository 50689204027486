.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
}
.titleContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 16px;
}
.questions {
  display: grid;
  margin-top: 40px;
  gap: 16px;

  @media (min-width: 1024px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
.topicButton {
  min-height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-wrap: wrap;
  font-size: 12px;
  border-color: #14141733;
  color: #141417;

  @media (min-width: 760px) {
    font-size: 14px;
  }

  @media (min-width: 1024px) {
    font-size: 16px;
  }
}
