.footer_container {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  font-size: 28px;
  font-weight: 700;
}

.linkBtn {
  height: auto;
  padding: 4px 12px;
  font-size: 28px;
  line-height: 32px;
  font-weight: 700;
}
