.container {
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.sender_you {
  text-align: end;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 12px;
  color: #d31e25;
}
.sender_truckanswers {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 12px;
  color: #d31e25;
}

.messages {
  font-size: 24px;
  border-radius: 24px;
  padding: 24px 40px;
  background-color: #c3c4c94d;
}

.sendbtn {
  position: absolute;
  right: 24px;
  padding: 0;
  font-weight: 700;
}
.inputContainer {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  line-height: 20px;
}
.radioBtn {
  width: 100%;
  border: none;
}
.submitBtn {
  margin: 40px auto;
  display: flex;
  height: 58px;
  width: 168px;
  align-items: center;
  justify-content: center;
}
.topicButton {
  width: 100%;
  border-color: #14141733;
  color: #141417;
}
.checkBoxContainer {
  width: 100%;
  position: relative;
  display: flex;
}

.checkBoxLabel {
  padding: 12px 24px;
  font-size: 20px;
  border-radius: 24px;
  background-color: #fff;
  z-index: 1;
}

.container :global(.ant-checkbox) {
  display: none;
}
.container :global(.ant-checkbox-wrapper) {
  display: flex;
  flex-grow: 1;
}
.container :global(.ant-checkbox-wrapper span) {
  flex-grow: 1;
  padding: 0;
}
.container :global(.ant-checkbox-wrapper-checked) .checkBoxLabel {
  color: #f1f2f5;
  background-color: #141417;
}
