.container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  justify-content: center;
  gap: 24px;
}

.topicContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.topicButton {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: #14141733;
  color: #141417;
}

.image {
  width: 100%;
  border-radius: 24px;
}
