#noBorder {
  border-inline-end: none;
}

#navLink {
  display: flex;
  gap: 12px;
  padding: 15px 17px;
  border-radius: 0 10px 10px 0;
  color: #434754;
}
#navLink:hover {
  background-color: #d8d9da;
}

.label {
  color: inherit;
}
