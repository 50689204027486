.chartContainer {
  max-width: 100%;
  width: 100%;
  padding: 32px;
  border: 2px solid #e4e4e4;
  border-radius: 16px;
  overflow: auto;
}

.titleContainer {
  display: flex;
  gap: 4px;
}

.title {
  text-transform: capitalize;
}

.moreInfo {
  width: fit-content;
}

.titleContainer .title {
  margin: 0;
}
