.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.wrapper {
  min-width: 1024px;
}

::-webkit-scrollbar {
  width: 12px; /* ширина для вертикального скролла */
  height: 10px; /* высота для горизонтального скролла */
  background-color: #fff;
}

::-webkit-scrollbar-thumb {
  background-color: #e4e4e4;
  border-radius: 24px;
}
